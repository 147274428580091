import React, { useState, useEffect } from "react";
import "./css/CompanyData.css";

interface Activity {
  code: string;
  text: string;
}

interface Partner {
  nome: string;
  qual: string;
}

interface CompanyDataResponse {
  abertura: string;
  atividade_principal: Activity[];
  atividades_secundarias: Activity[];
  bairro: string;
  billing: {
    database: boolean;
    free: boolean;
  };
  capital_social: string;
  cep: string;
  cnpj: string;
  complemento: string;
  data_situacao: string;
  data_situacao_especial: string;
  efr: string;
  email: string;
  extra: object; // Adjust if you have more details about this field
  fantasia: string;
  logradouro: string;
  motivo_situacao: string;
  municipio: string;
  natureza_juridica: string;
  nome: string;
  numero: string;
  porte: string;
  qsa: Partner[];
  situacao: string;
  situacao_especial: string;
  status: string;
  telefone: string;
  tipo: string;
  uf: string;
  ultima_atualizacao: string;
}

const CompanyData: React.FC = () => {
  const [cnpj, setCnpj] = useState<string>("");
  const [companyData, setCompanyData] = useState<CompanyDataResponse | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSearch = () => {
    const cleanedCnpj = cnpj.replace(/\D/g, "");

    if (cleanedCnpj.trim()) {
      setCnpj(cleanedCnpj);
      fetchCompanyData(cleanedCnpj);
    } else {
      setError("Please enter a valid CNPJ.");
    }
  };

  const fetchCompanyData = (cleanedCnpj: string) => {
    setLoading(true);
    setError(null);

    const callbackName = `jsonp_callback_${Date.now()}`;

    // Define the callback function in the global scope
    (window as any)[callbackName] = (data: CompanyDataResponse) => {
      setCompanyData(data);
      localStorage.setItem("companyData", JSON.stringify(data));
      setLoading(false);
    };

    // Create a script tag to request the JSONP data
    const script = document.createElement("script");
    script.src = `https://receitaws.com.br/v1/cnpj/${cleanedCnpj}?callback=${callbackName}`;
    script.onerror = () => {
      setError("Error fetching company data");
      setLoading(false);
    };

    document.body.appendChild(script);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // Load data from localStorage on mount
  useEffect(() => {
    const savedData = localStorage.getItem("companyData");
    if (savedData) {
      setCompanyData(JSON.parse(savedData));
    }
  }, []);

  return (
    <div className="company-data-container">
      <div className="search-section">
        <input
          type="text"
          value={cnpj}
          onChange={(e) => setCnpj(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter CNPJ"
          className="search-input"
        />
        <button
          onClick={handleSearch}
          className="search-button"
          disabled={loading}
        >
          {loading ? "Searching..." : "Search"}
        </button>
      </div>

      {error && <p className="error">{error}</p>}

      {companyData && !loading && (
        <div className="company-details">
          <h2>Company Details</h2>
          <table className="company-table">
            <tbody>
              <tr>
                <td>Official Name</td>
                <td>{companyData.nome}</td>
              </tr>
              <tr>
                <td>Trade Name</td>
                <td>{companyData.fantasia}</td>
              </tr>
              <tr>
                <td>Tax ID Number</td>
                <td>{companyData.cnpj}</td>
              </tr>
              <tr>
                <td>Founded Date</td>
                <td>{companyData.abertura}</td>
              </tr>
              <tr>
                <td>Capital</td>
                <td>{companyData.capital_social}</td>
              </tr>
              <tr>
                <td>Primary Address</td>
                <td>
                  {companyData.logradouro}, {companyData.numero},{" "}
                  {companyData.bairro}, {companyData.municipio} -{" "}
                  {companyData.uf}, {companyData.cep}
                </td>
              </tr>
              <tr>
                <td>Primary Email</td>
                <td>{companyData.email}</td>
              </tr>
              <tr>
                <td>Primary Phone</td>
                <td>{companyData.telefone}</td>
              </tr>
              <tr>
                <td>Legal Nature</td>
                <td>{companyData.natureza_juridica}</td>
              </tr>
              <tr>
                <td>Company Status</td>
                <td>{companyData.situacao}</td>
              </tr>
              <tr>
                <td>Special Status</td>
                <td>{companyData.situacao_especial}</td>
              </tr>
              <tr>
                <td>Update Date</td>
                <td>{companyData.ultima_atualizacao}</td>
              </tr>
              <tr>
                <td>Primary Activity</td>
                <td>{companyData.atividade_principal[0].text}</td>
              </tr>
              {companyData.atividades_secundarias.length > 0 && (
                <tr>
                  <td>Secondary Activities</td>
                  <td>
                    <ul>
                      {companyData.atividades_secundarias.map(
                        (activity, index) => (
                          <li key={index}>{activity.text}</li>
                        )
                      )}
                    </ul>
                  </td>
                </tr>
              )}
              <tr>
                <td>Partner(s)</td>
                <td>
                  <ul>
                    {companyData.qsa.map((partner, index) => (
                      <li key={index}>
                        {partner.nome} - {partner.qual}
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CompanyData;
