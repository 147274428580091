import React from "react";
import "./css/Home.css";

const Home: React.FC = () => {
  return (
    <div className="home-container">
      <header className="hero-section">
        <div className="hero-content">
          <h1>Welcome to BigDataCorp Integration</h1>
          <p className="hero-subtitle">
            Your one-stop solution for comprehensive company and personal data retrieval.
          </p>
        </div>
      </header>
      <main className="content-section" id="features">
        <section className="feature">
          <div className="feature-icon">🏢</div>
          <h2>In-Depth Company Information</h2>
          <p>
            Discover in-depth information about companies registered in Brazil
            with our advanced data retrieval system.
          </p>
        </section>
        <section className="feature">
          <div className="feature-icon">🧑‍💼</div>
          <h2>Detailed Personal Data</h2>
          <p>
            Access detailed personal information including CPF, full names, and
            contact details with our robust API integration.
          </p>
        </section>
        <section className="feature">
          <div className="feature-icon">🔗</div>
          <h2>Seamless API Integration</h2>
          <p>
            Seamlessly integrate our API into your applications to access real-time
            company and personal data.
          </p>
        </section>
        <section className="feature">
          <div className="feature-icon">🛠️</div>
          <h2>Robust Support</h2>
          <p>
            Enjoy robust support and comprehensive documentation to help you
            make the most of our platform.
          </p>
        </section>
      </main>
    </div>
  );
};

export default Home;
