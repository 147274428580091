import "./App.css";
import Sidebar from "./components/Sidebar";
import Home from "./pages/Home";
import CompanyData from "./pages/CompanyData";
import PersonData from "./pages/PersonData";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="app">
        <Sidebar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="companyData" element={<CompanyData />} />
            <Route path="personData" element={<PersonData />}/>
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
