import React, { useState, useEffect } from "react";
import axios from "axios";
import "./css/PersonData.css";

interface PersonDataResponse {
  Result: {
    RegistrationData: {
      BasicData: {
        BirthDate: string;
        Gender: string;
        MotherName: string;
        Name: string;
        TaxIdNumber: string;
      };
      Addresses: {
        Primary: {
          AddressMain: string;
          City: string;
          Complement: string;
          Country: string;
          State: string;
          ZipCode: string;
        };
        Secondary?: {
          AddressMain: string;
          City: string;
          Complement: string;
          Country: string;
          State: string;
          ZipCode: string;
        };
      };
      Emails: {
        Primary: {
          EmailAddress: string;
        };
        Secondary?: {
          EmailAddress: string;
        };
      };
      Phones: {
        Primary: {
          AreaCode: string;
          Number: string;
        };
        Secondary?: {
          AreaCode: string;
          Number: string;
        };
      };
    };
  }[];
}

interface FinancialRiskResponse {
  Result: {
    FinancialRisk: {
      TotalAssets: string;
      EstimatedIncomeRange: string;
      IsCurrentlyEmployed: boolean;
      IsCurrentlyOwner: boolean;
      LastOccupationStartDate: string;
      IsCurrentlyOnCollection: boolean;
      Last365DaysCollectionOccurrences: number;
      CurrentConsecutiveCollectionMonths: number;
      IsCurrentlyReceivingAssistance: boolean;
      FinancialRiskScore: number;
      FinancialRiskLevel: string;
    };
  }[];
}

interface AppsNetworksPlatformsResponse {
  Result: {
    AppsNetworksAndPlatforms: {
      HasFacebookProfile: boolean;
      HasTwitterProfile: boolean;
      HasLinkedInProfile: boolean;
      HasInstagramProfile: boolean;
      HasPinterestProfile: boolean;
      HasGithubProfile: boolean;
      HasOLXPresence: boolean;
      HasMercadoLivrePresence: boolean;
      TotalSocialNetworks: number;
      TotalSearchResults: number;
      TotalSocialMediaPosts: number;
      SocialMediaFollowerToFollowingRatio: number;
      AppProfiles: [
        {
          AppName: string;
          DisplayName: string;
          ProfileUrl: string;
          DisplayNameMatchRate: number;
          UserNameMatchRate: number;
          AdditionalInformation: {
            Jobtitle: string;
          };
          FirstCaptureDate: string;
          LastCaptureDate: string;
        }
      ];
      SearchEngineResults: [
        {
          SearchEngineName: string;
          PageTitle: string;
          PageDescription: string;
          PageUrl: string;
          FirstCaptureDate: string;
          LastCaptureDate: string;
        }
      ];
    };
  }[];
}

interface GovernmentDebtorsResponse {
  Result: {
    GovernmentDebtors: {
      TotalDebtValue: number;
      TotalDebtValuePerOrigin: {};
      TotalDebts: number;
      TotalDebtsPerOrigin: {};
      Debts: [];
    };
  }[];
}

const PersonData: React.FC = () => {
  const [cpf, setCpf] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [personData, setPersonData] = useState<PersonDataResponse | null>(null);
  const [financialRiskData, setFinancialRiskData] =
    useState<FinancialRiskResponse | null>(null);
  const [appsNetworksPlatformsData, setAppsNetworksPlatformsData] =
    useState<AppsNetworksPlatformsResponse | null>(null);
  const [governmentDebtorsData, setGovernmentDebtorsData] =
    useState<GovernmentDebtorsResponse | null>(null);
  const AccessToken = process.env.REACT_APP_ACCESS_TOKEN;
  const tokenId = process.env.REACT_APP_TOKEN_ID;

  const fetchPersonData = async () => {
    const options = {
      method: "POST",
      url: "/api/bigdatacorp/pessoas",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        AccessToken: AccessToken,
        TokenId: tokenId,
      },
      data: { q: `doc {${cpf}}`, Datasets: "registration_data", Limit: 1 },
    };

    setLoading(true);
    setError(null);

    try {
      const response = await axios.request(options);

      setPersonData(response.data);
      localStorage.setItem("personData", JSON.stringify(response.data));
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.error("Axios error:", err);
        setError(err.message || "Error fetching person data");
      } else {
        console.error("General error:", err);
        setError("Error fetching person data");
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchAppsNetworksPlatforms = async () => {
    const options = {
      method: "POST",
      url: "/api/bigdatacorp/pessoas",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        AccessToken: AccessToken,
        TokenId: tokenId,
      },
      data: {
        q: `doc {${cpf}}`,
        Datasets: "apps_networks_and_platforms.filter(appname=linkedin)",
        Limit: 1,
      },
    };

    setLoading(true);
    setError(null);

    try {
      const response = await axios.request(options);

      setAppsNetworksPlatformsData(response.data);
      localStorage.setItem(
        "appsNetworksPlatforms",
        JSON.stringify(response.data)
      );
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.error("Axios error:", err);
        setError(
          err.message || "Error fetching Apps Networks and Platforms data"
        );
      } else {
        console.error("General error:", err);
        setError("Error fetching Apps Networks and Platforms data");
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchFinancialRisk = async () => {
    const options = {
      method: "POST",
      url: "/api/bigdatacorp/pessoas",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        AccessToken: AccessToken,
        TokenId: tokenId,
      },
      data: { q: `doc {${cpf}}`, Datasets: "financial_risk", Limit: 1 },
    };

    setLoading(true);
    setError(null);

    try {
      const response = await axios.request(options);

      setFinancialRiskData(response.data);
      localStorage.setItem("financialRiskData", JSON.stringify(response.data));
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.error("Axios error:", err);
        setError(err.message || "Error fetching financial risk data");
      } else {
        console.error("General error:", err);
        setError("Error fetching financial risk data");
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchGovernmentDebtors = async () => {
    const options = {
      method: "POST",
      url: "/api/bigdatacorp/pessoas",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        AccessToken: AccessToken,
        TokenId: tokenId,
      },
      data: { q: `doc {${cpf}}`, Datasets: "government_debtors", Limit: 1 },
    };

    setLoading(true);
    setError(null);

    try {
      const response = await axios.request(options);

      setGovernmentDebtorsData(response.data);
      localStorage.setItem("governmentDebtorsData", JSON.stringify(response.data));
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.error("Axios error:", err);
        setError(err.message || "Error fetching Government Debtors data");
      } else {
        console.error("General error:", err);
        setError("Error fetching Government Debtors data");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    if (cpf.trim()) {
      fetchPersonData();
      fetchFinancialRisk();
      fetchAppsNetworksPlatforms();
      fetchGovernmentDebtors();
    } else {
      setError("Please enter a CPF.");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    const savedPersonData = localStorage.getItem("personData");
    const savedFinancialRiskData = localStorage.getItem("financialRiskData");
    const savedAppsNetworksPlatformsData = localStorage.getItem(
      "appsNetworksPlatforms"
    );
    const savedGovernmentDebtorsData = localStorage.getItem(
      "governmentDebtorsData"
    );
    if (savedPersonData) {
      setPersonData(JSON.parse(savedPersonData));
    }
    if (savedFinancialRiskData) {
      setFinancialRiskData(JSON.parse(savedFinancialRiskData));
    }
    if (savedAppsNetworksPlatformsData) {
      setAppsNetworksPlatformsData(JSON.parse(savedAppsNetworksPlatformsData));
    }
    if (savedGovernmentDebtorsData) {
      setGovernmentDebtorsData(JSON.parse(savedGovernmentDebtorsData));
    }
  }, []);

  const person = personData?.Result?.[0]?.RegistrationData;
  const financialRisk = financialRiskData?.Result?.[0]?.FinancialRisk;
  const appsNetworks =
    appsNetworksPlatformsData?.Result?.[0]?.AppsNetworksAndPlatforms;
  const govDebtors = governmentDebtorsData?.Result?.[0]?.GovernmentDebtors;

  return (
    <div className="person-data-container">
      <div className="search-section">
        <input
          type="text"
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter CPF"
          className="search-input"
        />
        <button
          onClick={handleSearch}
          className="search-button"
          disabled={loading}
        >
          {loading ? "Searching..." : "Search"}
        </button>
      </div>

      {error && <p className="error">{error}</p>}

      {person && !loading && (
        <div className="person-details">
          <h2>Person Details</h2>
          <table className="person-table">
            <tbody>
              <tr>
                <td>Name</td>
                <td>{person.BasicData?.Name}</td>
              </tr>
              <tr>
                <td>Birth Date</td>
                <td>{person.BasicData?.BirthDate}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>{person.BasicData?.Gender}</td>
              </tr>
              <tr>
                <td>Mother's Name</td>
                <td>{person.BasicData?.MotherName}</td>
              </tr>
              <tr>
                <td>Tax ID Number</td>
                <td>{person.BasicData?.TaxIdNumber}</td>
              </tr>
              <tr>
                <td>Primary Address</td>
                <td>
                  {`${person.Addresses?.Primary?.AddressMain}, ${person.Addresses?.Primary?.City}, ${person.Addresses?.Primary?.State}, ${person.Addresses?.Primary?.ZipCode}`}
                </td>
              </tr>

              {person.Addresses?.Secondary && (
                <tr>
                  <td>Secondary Address</td>
                  <td>
                    {`${person.Addresses.Secondary.AddressMain},${person.Addresses.Secondary.City},${person.Addresses.Secondary.State},${person.Addresses.Secondary.ZipCode}`}
                  </td>
                </tr>
              )}
              <tr>
                <td>Primary Email</td>
                <td>{person.Emails?.Primary?.EmailAddress}</td>
              </tr>
              {person.Emails?.Secondary && (
                <tr>
                  <td>Secondary Email</td>
                  <td>{person.Emails.Secondary.EmailAddress}</td>
                </tr>
              )}
              <tr>
                <td>Primary Phone</td>
                <td>
                  ({person.Phones?.Primary?.AreaCode}){" "}
                  {person.Phones?.Primary?.Number}
                </td>
              </tr>
              {person.Phones?.Secondary && (
                <tr>
                  <td>Secondary Phone</td>
                  <td>
                    ({person.Phones.Secondary.AreaCode})
                    {person.Phones.Secondary.Number}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {financialRisk && !loading && (
        <div className="financial-risk-details">
          <h2>Financial Risk Details</h2>
          <table className="financial-risk-table">
            <tbody>
              <tr>
                <td>Total Assets</td>
                <td>{financialRisk.TotalAssets}</td>
              </tr>
              <tr>
                <td>Estimated Income Range</td>
                <td>{financialRisk.EstimatedIncomeRange}</td>
              </tr>
              <tr>
                <td>Employed</td>
                <td>{financialRisk.IsCurrentlyEmployed ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>Owner</td>
                <td>{financialRisk.IsCurrentlyOwner ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>Last Occupation Start Date</td>
                <td>{financialRisk.LastOccupationStartDate}</td>
              </tr>
              <tr>
                <td>On Collection</td>
                <td>{financialRisk.IsCurrentlyOnCollection ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <td>Last 365 Days Collection Occurrences</td>
                <td>{financialRisk.Last365DaysCollectionOccurrences}</td>
              </tr>
              <tr>
                <td>Current Consecutive Collection Months</td>
                <td>{financialRisk.CurrentConsecutiveCollectionMonths}</td>
              </tr>
              <tr>
                <td>Receiving Assistance</td>
                <td>
                  {financialRisk.IsCurrentlyReceivingAssistance ? "Yes" : "No"}
                </td>
              </tr>
              <tr>
                <td>Financial Risk Score</td>
                <td>{financialRisk.FinancialRiskScore}</td>
              </tr>
              <tr>
                <td>Financial Risk Level</td>
                <td>{financialRisk.FinancialRiskLevel}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {appsNetworks && !loading && (
        <div className="apps-networks-detail">
          <h2>Apps Networks and Platforms</h2>
          <table className="apps-networks-table">
            <tbody>
              {appsNetworks.AppProfiles.map((profile, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>App Name</td>
                    <td>{profile.AppName}</td>
                  </tr>
                  <tr>
                    <td>Display Name</td>
                    <td>{profile.DisplayName}</td>
                  </tr>
                  <tr>
                    <td>Profile URL</td>
                    <td>{profile.ProfileUrl}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {govDebtors && !loading && (
        <div className="gov-debtors-detail">
          <h2>Government Debtors</h2>
          <table className="gov-debtors-table">
            <tbody>
              <tr>
                <td>Debts</td>
                <td>{govDebtors.Debts.length}</td>{" "}
                {/* Display the length of the array */}
              </tr>
              <tr>
                <td>Total Debts</td>
                <td>{govDebtors.TotalDebts}</td>
              </tr>
              <tr>
                <td>Total Debt Value</td>
                <td>{govDebtors.TotalDebtValue}</td>
              </tr>
              <tr>
                <td>Total Debts Per Origin</td>
                <td>{JSON.stringify(govDebtors.TotalDebtsPerOrigin)}</td>{" "}
                {/* Convert object to string */}
              </tr>
              <tr>
                <td>Total Debt Value Per Origin</td>
                <td>
                  {JSON.stringify(govDebtors.TotalDebtValuePerOrigin)}
                </td>{" "}
                {/* Convert object to string */}
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PersonData;
