import React, { useState, useEffect } from "react";
import "./css/Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faHouseChimney as faHouseChimneyUser,
  faPerson,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import AsciiCat from "./AsciiCat";

function Sidebar() {
  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  return (
    <>
      <div className="sidebar">
        <h2 className="title-sidebar">
          <Link to="/">BigDataCorp Integration</Link> {/* Use Link for Home */}
        </h2>
        <ul>
          <li className="sidebar-item">
            <div className="icon-column">
              <Link to="/"><FontAwesomeIcon icon={faHouseChimneyUser} /></Link>
            </div>
            <div className="text-column">
              <Link to="/">Home</Link>
            </div>
          </li>
          <li className="sidebar-item">
            <div className="icon-column">
              <Link to="companyData"><FontAwesomeIcon icon={faBuilding} /></Link>
            </div>
            <div className="text-column">
              <Link to="companyData">Company Data</Link>
            </div>
          </li>
          <li className="sidebar-item">
            <div className="icon-column">
              <Link to="personData"><FontAwesomeIcon icon={faPerson} /></Link>
            </div>
            <div className="text-column">
              <Link to="personData">Person Data</Link>
            </div>
          </li>
          <li className="sidebar-item list-bottom">
            <div className="icon-column">
            <a href="#" onClick={closeTab}><FontAwesomeIcon icon={faSignOutAlt} /></a>
            </div>
            <div className="text-column">
              <a href="#" onClick={closeTab}>
                Sair
              </a>
            </div>
          </li>
        </ul>
      </div>
      <AsciiCat />
    </>
  );
}

export default Sidebar;
